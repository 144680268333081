import "./src/style.css";
import * as $ from "jquery";
import React from "react";
import RootProvider from "./src/components/RootProvider";

export const wrapRootElement = ({ element }) => {
  return <RootProvider>{element}</RootProvider>;
};

// jQuery scroll
// TODO: Remove jquery from codebase
$(function () {
  const updateHeader = () => {
    const header = $(".header");
    if ($(this).scrollTop() > 0) {
      header.addClass("active");
    } else header.removeClass("active");

    $(".card").each(function (i, e) {
      if ($(window).scrollTop() + $(window).height() - 200 >= $(e).offset().top) {
        $(e).addClass("card-anim");
      }
      /* if ($(e).offset().top < ($(window).scrollTop() + $(window).height())) {
          $(e).addClass('card-anim');
      } */
    });
  };

  $(window).on("scroll", () => updateHeader());
  // update header on page load
  updateHeader();

  $(document).on("click", ".dropdown-list-item", function () {
    $(".dropdown-list-item").removeClass("selected");
    $(this).addClass("selected");

    const target = $(this).attr("data-value");
    const option = $(this).parents(".custom-select").find("option");
    option.attr("data-value", target);
    option.text(target);
    $(".dropdown-selected").text(target);
  });

  $(document).on("mouseup", function (e) {
    var container = $(".dropdown");

    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      $(".dropdown-list").removeClass("active");
    }
  });
});

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  const { pathname } = location;
  if (pathname.includes("dapp-store")) {
    return false;
  }
  return true;
};
