exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-accelerator-tsx": () => import("./../../../src/pages/accelerator.tsx" /* webpackChunkName: "component---src-pages-accelerator-tsx" */),
  "component---src-pages-community-tsx": () => import("./../../../src/pages/community.tsx" /* webpackChunkName: "component---src-pages-community-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-dapp-store-tsx": () => import("./../../../src/pages/dapp-store.tsx" /* webpackChunkName: "component---src-pages-dapp-store-tsx" */),
  "component---src-pages-demo-day-tsx": () => import("./../../../src/pages/demo-day.tsx" /* webpackChunkName: "component---src-pages-demo-day-tsx" */),
  "component---src-pages-grant-webpage-tsx": () => import("./../../../src/pages/grant-webpage.tsx" /* webpackChunkName: "component---src-pages-grant-webpage-tsx" */),
  "component---src-pages-hackathon-tsx": () => import("./../../../src/pages/hackathon.tsx" /* webpackChunkName: "component---src-pages-hackathon-tsx" */),
  "component---src-pages-incubator-tsx": () => import("./../../../src/pages/incubator.tsx" /* webpackChunkName: "component---src-pages-incubator-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kyc-tsx": () => import("./../../../src/pages/kyc.tsx" /* webpackChunkName: "component---src-pages-kyc-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */)
}

